import withValidations from "@/mixins/withValidations"
import { transform, map, values } from "lodash-es"

const validationsMixin = withValidations(({ required, minLength }) => ({
  itemName: {
    required,
    minLength: minLength(1)
  }
}))

export default sourcesName => ({
  props: {
    otaList: {
      type: Array,
      require: true
    },
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    updateItem: {
      type: Function,
      require: true
    },
    deleteItem: {
      type: Function,
      require: true
    },
    sourcesItemsByOtaId: {
      type: Object,
      default: () => new Object()
    },
    immutableOtaIds: {
      type: Array,
      default: () => new Array()
    },
    selectedSourcesItemsByOtaId: {
      type: Object,
      default: () => new Object()
    },
    addSelectedSourcesItem: {
      type: Function,
      require: true
    },
    removeSelectedSourcesItem: {
      type: Function,
      require: true
    },
    sourcesItemsLoading: {
      type: Boolean,
      default: true
    },
    invalidItemIds: {
      type: Array,
      default: () => new Array()
    }
  },

  mixins: [validationsMixin],

  data() {
    return {
      itemName: this.item.name
    }
  },

  mounted() {
    if (this.item._addedAt) {
      this.setItemName({ target: { value: "" } })
    }
  },

  computed: {
    sourcesItems({ item }) {
      return item[sourcesName]
    },

    sourcesItemIds() {
      return map(this.sourcesItems, "id")
    },

    currentSourcesItemsByOtaId() {
      return transform(
        this.sourcesItems,
        (object, sourcesItem) => {
          object[sourcesItem.ota_id] = sourcesItem
          return object
        },
        {}
      )
    },

    isInvalid({ item: { id, name } }) {
      return this.invalidItemIds.includes(id) || (!id && this.invalidItemIds.includes(name))
    },

    isAdded() {
      return !!this.item._addedAt
    }
  },

  methods: {
    dropDownItems(otaId, itemDp = false) {
      if (this.isSourceItemSelectionDisabled(otaId)) {
        return [this.currentSourcesItemsByOtaId[otaId]]
      } else {
        return (this.sourcesItemsByOtaId[otaId] || []).filter(({ dp }) => dp === itemDp)
      }
    },

    isSourceItemSelectionDisabled(otaId) {
      return this.immutableOtaIds.includes(otaId)
    },

    denySelectSourcesItem({ id, ota_id }) {
      if (this.sourcesItemIds.includes(id)) return true
      return (this.selectedSourcesItemsByOtaId[ota_id] || []).includes(id)
    },

    updateItemPartial(changes) {
      const item = { ...this.item, ...changes }
      this.updateItem({ item, index: this.index })
    },

    setItemName({ target: { value } }) {
      this.itemName = value
      this.validateAttributes()

      this.updateItemPartial({ name: value, isInvalid: !this.isValid })
    },

    setItemSync({ value }) {
      this.updateItemPartial({ sync: value })
    },

    setItemDp(dp) {
      this.updateItemPartial({ [sourcesName]: [], dp })

      values(this.currentSourcesItemsByOtaId).forEach(item => {
        this.removeSelectedSourcesItem(item)
      })
    },

    setItemInbound(inbound) {
      this.updateItemPartial({ inbound: inbound })
    },

    setSourcesItem(item) {
      const oldItem = this.currentSourcesItemsByOtaId[item.ota_id]
      const currentSourcesItemsByOtaId = { ...this.currentSourcesItemsByOtaId, [item.ota_id]: item }
      const newSourcesItems = Object.values(currentSourcesItemsByOtaId)
      this.updateItemPartial({ [sourcesName]: newSourcesItems })
      this.addSelectedSourcesItem({ item, oldItem })
    },

    removeSourcesItem(item) {
      const { ota_id } = item
      const newSourcesItems = transform(
        this.currentSourcesItemsByOtaId,
        (array, sourcesItem) => {
          sourcesItem.ota_id !== ota_id && array.push(sourcesItem)
        },
        []
      )
      this.updateItemPartial({ [sourcesName]: newSourcesItems })
      this.removeSelectedSourcesItem(item)
    },

    deleteItemHandle() {
      this.sourcesItems.forEach(sourcesItem => this.removeSelectedSourcesItem(sourcesItem))
      this.deleteItem(this.index)
    }
  }
})
