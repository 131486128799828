<template lang="pug">
  tr.matchings-item(:class="{ added: isAdded }")
    td.sticky-col.sync
      AppToggle(
        :value="item.sync"
        :disabled="!hasEditPermission()"
        :title="item.sync ? $t('car_class_matching.sync_toggle_tooltip') : ''"
        v-b-tooltip.hover
        @change="setItemSync"
      )
    td.sticky-col.editable.item-name
      input.editable-field.form-control(
        :class="{ invalid: isInvalid }"
        type="text"
        :value="itemName"
        :disabled="!hasEditPermission()"
        @input="setItemName"
      )
    td.dp(v-if="currentOrganization.dp")
      AppCheckbox.highlight-checked(
        :value="item.dp"
        :disabled="!!item.id"
        @change="setItemDp"
      )
    td.inbound(v-if="currentOrganization.inbound")
      AppCheckbox.highlight-checked(
        :value="item.inbound"
        @change="setItemInbound"
      )
    td.sources-item(
      v-for="ota in otaList"
      :key="ota.id"
      :class="ota.rawName"
    )
      .immutable(
        v-if="isSourceItemSelectionDisabled(ota.id)"
        :class="{ inactive: !currentSourcesItemsByOtaId[ota.id].active || !item.sync }"
        :title="currentSourcesItemsByOtaId[ota.id].name"
      )
        span.m-r-3(v-if="!currentSourcesItemsByOtaId[ota.id].active")
          | ({{ $t("car_class_matching.inactive") }})
        span {{ currentSourcesItemsByOtaId[ota.id].name }}
      AppDropdown(
        v-else
        lazy-scroll
        z-index="103"
        size="small"
        close-on-select
        searchable
        allow-empty
        title-key="name"
        value-key="id"
        :items="dropDownItems(ota.id, item)"
        :value="currentSourcesItemsByOtaId[ota.id]"
        :placeholder="$t('car_class_matching.select_car_class')"
        :is-item-disabled="denySelectSourcesItem"
        :class="{ inactive: !item.sync }"
        @select="setSourcesItem"
        @remove="removeSourcesItem"
      )
        template(
          v-if="sourcesItemsLoading"
          v-slot:selectIcon
        )
          FaIcon.app-select-icon(
            icon="spinner"
            spin
          )
      td.sticky-col.actions.d-inline-flex
        .action-info
          AppIconButton.info-icon(
            useAppIcon
            icon="exclamationCircle"
            v-if="item.id"
            @click="openModal()"
          )
        AppIconButton.action-delete(
          useAppIcon
          v-if="hasEditPermission()"
          icon="trash"
          @click="deleteItemHandle"
        )
</template>

<script>
  // mixins
  import withPermissions from "@/mixins/withPermissions"
  import withMatchingPageMethods from "@/mixins/matching_page/withMatchingItemMethods"
  import withModal from "@/mixins/withModal"

  import { mapGetters } from "vuex"

  export default {
    props: {
      otaList: {
        type: Array,
        default: () => new Array()
      }
    },

    components: {
      AppToggle: () => import("@/components/elements/AppToggle"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other"),
      AppCheckbox: () => import("@/components/elements/AppCheckbox")
    },

    computed: {
      ...mapGetters(["currentOrganization"])
    },

    methods: {
      dropDownItems(otaId, item) {
        if (this.isSourceItemSelectionDisabled(otaId)) {
          return [this.currentSourcesItemsByOtaId[otaId]]
        } else {
          return (this.sourcesItemsByOtaId[otaId] || []).filter(({ dp, companies_car_class_id: cccId }) => {
            return item.dp ? !!dp || !!cccId : !dp
          })
        }
      },
      openModal() {
        this.$openModal({
          title: this.$t("header.title.sync_list"),
          component: () => import("@/components/SyncList/CarClassMatchingModal"),
          size: "large",
          props: {
            carClass: this.item
          }
        })
      }
    },

    mixins: [withMatchingPageMethods("sources_car_classes"), withPermissions, withModal]
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/matchings.sass"

  .matchings-item
    td
      +matchings-item-td

      &.inbound, &.dp
        padding-left: 0

        .app-checkbox
          justify-content: center

    +matchings-item-edit
</style>
